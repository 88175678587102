<template>
  <div>
    <v-list
      two-line
      subheader
    >
      <v-subheader>MATLAB </v-subheader>

      <v-list-tile avatar>
        <v-list-tile-avatar>
          <v-icon>mdi-code-greater-than</v-icon>
        </v-list-tile-avatar>

        <v-list-tile-content>
          <v-list-tile-title>MATLAB</v-list-tile-title>
          <v-list-tile-sub-title>Execute code to generate plot</v-list-tile-sub-title>
        </v-list-tile-content>

        <v-list-tile-action>
          <v-btn
            icon
            ripple
          >
            <v-icon
              @click="writeCodeToClipboard(matlabCode)"
            >
              mdi-content-copy
            </v-icon>
          </v-btn>
        </v-list-tile-action>
      </v-list-tile>
    </v-list>

    <v-card-text
      v-if="writeRun"
      class="pt-0 mt-0"
    >
      <v-container>
        <v-row>
          <v-col
            cols="12"
          >
            <v-autocomplete
              v-model="selectedItem"
              :disabled="isUpdating"
              :items="items"
              color="blue-grey lighten-2"
              label="Select raw IVP"
              item-text="id"
              item-value="rawivp"
            >
              <template #selection="data">
                <span v-if="data.item.current_min !== data.item.current_max && data.item.current_shape === 'dc'">
                  <v-list-tile-title v-html="data.item.temperature_min + '°C ' + data.item.current_shape + ': ' + data.item.current_min + ' - ' + data.item.current_max + ' mA (' + data.item.current_type + ', ' + data.item.current_steps + ' steps)'" />
                </span>
                <span v-else-if="data.item.current_min !== data.item.current_max">
                  <v-list-tile-title v-html="data.item.temperature_min + '°C ' + data.item.current_shape + ': ' + data.item.current_min + ' - ' + data.item.current_max + ' mA, ' + data.item.duty_min + '%, ' + data.item.pulses_min + '/s, (' + data.item.current_type + ', ' + data.item.current_steps + ' steps)'" />
                </span>
                <span v-else-if="data.item.pulses_min !== data.item.pulses_max">
                  <v-list-tile-title v-html="data.item.temperature_min + '°C ' + data.item.current_shape + ': ' + data.item.pulses_min + ' - ' + data.item.pulses_max + '/s, ' + data.item.duty_min + '%, ' + data.item.current_min + ' mA (' + data.item.pulse_type + ', ' + data.item.pulse_steps + ' steps)'" />
                </span>
                <span v-else-if="data.item.duty_min !== data.item.duty_max">
                  <v-list-tile-title v-html="data.item.temperature_min + '°C ' + data.item.current_shape + ': ' + data.item.duty_min + ' - ' + data.item.duty_max + '%, ' + data.item.pulses_min + '/s, ' + data.item.current_min + ' mA (' + data.item.duty_type + ', ' + data.item.duty_steps + ' steps)'" />
                </span>
                <span v-else-if="data.item.current_shape === 'dc'">
                  <v-list-tile-title v-html="data.item.temperature_min + '°C ' + data.item.current_shape + ': ' + data.item.duty_min + ' mA'" />
                </span>
                <span v-else>
                  <v-list-tile-title v-html="data.item.temperature_min + '°C ' + data.item.current_shape + ': ' + data.item.duty_min + '%, ' + data.item.pulses_min + '/s, ' + data.item.current_min + ' mA'" />
                </span>
              </template>
              <template #item="data">
                <template v-if="typeof data.item !== 'object'">
                  <v-list-tile-content v-text="data.item" />
                </template>
                <template v-else>
                  <v-list-tile-content>
                    <span v-if="data.item.current_min !== data.item.current_max && data.item.current_shape === 'dc'">
                      <v-list-tile-title v-html="data.item.temperature_min + '°C ' + data.item.current_shape + ': ' + data.item.current_min + ' - ' + data.item.current_max + ' mA (' + data.item.current_type + ', ' + data.item.current_steps + ' steps)'" />
                    </span>
                    <span v-else-if="data.item.current_min !== data.item.current_max">
                      <v-list-tile-title v-html="data.item.temperature_min + '°C ' + data.item.current_shape + ': ' + data.item.current_min + ' - ' + data.item.current_max + ' mA, ' + data.item.duty_min + '%, ' + data.item.pulses_min + '/s, (' + data.item.current_type + ', ' + data.item.current_steps + ' steps)'" />
                    </span>
                    <span v-else-if="data.item.pulses_min !== data.item.pulses_max">
                      <v-list-tile-title v-html="data.item.temperature_min + '°C ' + data.item.current_shape + ': ' + data.item.pulses_min + ' - ' + data.item.pulses_max + '/s, ' + data.item.duty_min + '%, ' + data.item.current_min + ' mA (' + data.item.pulse_type + ', ' + data.item.pulse_steps + ' steps)'" />
                    </span>
                    <span v-else-if="data.item.duty_min !== data.item.duty_max">
                      <v-list-tile-title v-html="data.item.temperature_min + '°C ' + data.item.current_shape + ': ' + data.item.duty_min + ' - ' + data.item.duty_max + '%, ' + data.item.pulses_min + '/s, ' + data.item.current_min + ' mA (' + data.item.duty_type + ', ' + data.item.duty_steps + ' steps)'" />
                    </span>
                    <span v-else-if="data.item.current_shape === 'dc'">
                      <v-list-tile-title v-html="data.item.temperature_min + '°C ' + data.item.current_shape + ': ' + data.item.duty_min + ' mA'" />
                    </span>
                    <span v-else>
                      <v-list-tile-title v-html="data.item.temperature_min + '°C ' + data.item.current_shape + ': ' + data.item.duty_min + '%, ' + data.item.pulses_min + '/s, ' + data.item.current_min + ' mA'" />
                    </span>
                    <v-list-tile-sub-title
                      v-if="data.item.flag === 'ok'"
                      class="green--text darken-1"
                      v-html="timeStringConv(data.item.timestamp)"
                    />
                    <div v-else>
                      <v-list-tile-sub-title
                        v-if="data.item.comment"
                        class="amber--text darken-1"
                        v-html="timeStringConv(data.item.timestamp) + ': ' + data.item.comment"
                      />
                      <v-list-tile-sub-title
                        v-else
                        class="amber--text darken-1"
                        v-html="timeStringConv(data.item.timestamp)"
                      />
                    </div>
                  </v-list-tile-content>
                </template>
              </template>
            </v-autocomplete>
          </v-col>
          <v-col
            v-if="selectedObject.ivpfile"
            cols="12"
          >
            <object
              class="gnuplotSVG"
              :data="'https://cmsdb.darkcosmos.org/files/viewplot.php?file='+selectedObject.ivpfile+'&version=svg'+fetchSuffix"
            ><img
              class="gnuplotSVG"
              :src="'https://cmsdb.darkcosmos.org/files/viewplot.php?file='+selectedObject.ivpfile+'&version=svg'+fetchSuffix"
              type="image/svg+xml"
            ></object>
            <div
              v-if="selectedObject.flag === 'ok'"
              class="green--text darken-1"
              v-html="timeStringConv(selectedObject.timestamp)"
            />
            <div v-else>
              <div
                v-if="selectedObject.comment"
                class="amber--text darken-1"
                v-html="timeStringConv(selectedObject.timestamp) + ': ' + selectedObject.comment"
              />
              <div
                v-else
                class="amber--text darken-1"
                v-html="timeStringConv(selectedObject.timestamp)"
              />
            </div>
          </v-col>
        </v-row>
      </v-container>
      <pre
        v-if="donecustom && selectedItem !== undefined && selectedItem !== ''"
        v-highlightjs
      ><code class="matlab">{{ matlabCode }}</code></pre>
    </v-card-text>
  </div>
</template>

<script>
import moment from 'moment'
import { EventBus } from '../../scripts/event_bus'

import { mapGetters } from 'vuex'
export default {
  name: 'CorrectedIVP',

  props: {
    data: {
      type: Object,
      required: true,
      default: () => ({})
    },
    led: {
      type: Object,
      required: true,
      default: () => ({})
    },
    custom: {
      type: Array,
      required: true,
      default: () => ([])
    }
  },
  data () {
    return {
      fetchSuffix: '',
      donecustom: false,
      isUpdating: false,
      matlabCode: '',
      writeRun: false,
      items: [],
      spectra: {},
      selectedItem: '',
      params: '',
      selectedObject: {},
      RawAOs: {}
    }
  },
  computed: {
    ...mapGetters([
      'userData'
    ])
  },
  watch: {
    data: {
      handler: function (newValue) {
        const self = this
        setTimeout(function () {
          self.selectedItem = self.data.files[0].rawselection // update value for dropdown based on selection (if given)
        }, 1000)

        this.getRawIVP()
        this.updateMatlab()
      },
      deep: true
    },
    custom: {
      handler: function (newValue) {
        const self = this
        setTimeout(function () {
          self.selectedItem = self.data.files[0].rawselection // update value for dropdown based on selection (if given)
        }, 1000)

        this.updateMatlab()
      },
      deep: true
    },
    selectedItem: function () {
      const self = this
      this.items.forEach(function (item) {
        if (item.rawivp === self.selectedItem) {
          self.selectedObject = item
        }
      })
      this.updateMatlab()
    }
  },
  mounted () {
    if (process.env.NODE_ENV === 'development' && localStorage.getItem('devAPIKey')) {
      this.fetchSuffix = '&apikey=' + localStorage.getItem('devAPIKey')
    }

    //      let gnuplotScript = document.createElement('script')
    //      gnuplotScript.setAttribute('src', 'https://cmsdb.darkcosmos.org/gnuplot.js/addcss.js')
    //      document.head.appendChild(gnuplotScript)
    this.getRawIVP()
  },
  methods: {
    writeCodeToClipboard: function (codeToCopy) {
      console.log('Writing', codeToCopy, 'to clipboard')

      window.navigator.clipboard.writeText(codeToCopy).then(() => {
        EventBus.$emit('info', 'Matlab code copied to clipboard successfully')
      }, () => {
        EventBus.$emit('info', 'Copy to clipboard failed... Outdated browser?')
      })
    },
    updateMatlab: function () {
      this.donecustom = false
      this.getPDparams()
      this.getIVPAOs()
    },
    getPDparams: function () {
      const self = this
      this.custom.forEach(function (item) {
        if (item.field === 'Photodiode') {
          self.params = item.params
        }
      })
    },
    timeStringConv: function (timestamp) {
      const rightNow = moment()

      return moment.unix(timestamp).calendar(rightNow)
    },
    getIVPAOs: function () {
      if (this.selectedObject.id > 0) {
        this.$root.$data.si.getRawAOs([
          { key: 'coll', value: this.selectedObject.rawivp },
          { key: 'fileid', value: this.selectedObject.id }
        ], (response) => {
          this.RawAOs = []
          if (response.aos) {
            this.RawAOs = response.aos
          }

          console.log('got', this.RawAOs)
          this.writeMatlab()
          this.donecustom = true
        },
        (error) => {
          this.writeMatlab()
          this.donecustom = true
          console.log(error)
        })
      } else {
        this.writeMatlab()
        this.donecustom = true
      }
    },
    getRawIVP: function () {
      if (!this.loading) {
        this.loading = true

        this.$root.$data.si.getRawIVP([
          { key: 'led_id', value: this.led.id },
          { key: 'exp_id', value: this.data.id }

        ], resp => {
          this.ivp = resp
          const self = this

          this.ivp.ivp.forEach(function (element) {
            if (self.data.temperature_min === element.temperature_min && self.data.current_shape === element.current_shape && self.data.duty_type === element.duty_type && self.data.pulse_type === element.pulse_type) {
              self.items.push(element)
            }
          })

          this.loading = false
        })
      }
    },
    writeMatlab: function () {
      this.writeRun = false
      const dbs = []
      const aos = []
      let fileid = 0
      let collid = 0

      if (this.data.files) {
        if (this.data.files[0]) {
          fileid = this.data.files[0].fileid
          collid = this.data.files[0].colluuid
          if (this.data.files[0].aos) {
            this.data.files[0].aos.forEach(function (element) {
              aos.push("'" + element.ao_id + "'")
              dbs.push("'" + element.ltpda + "'")
            })
          }
        }
      }

      const rawdbs = []
      const rawaos = []

      if (this.RawAOs[0]) {
        this.RawAOs.forEach(function (element) {
          rawaos.push("'" + element.ao_id + "'")
          rawdbs.push("'ivp_dc_smu_reference'")
        })
      }

      console.log('used', this.RawAOs)

      // current settings

      let xlabel = ''
      let set = ''

      if (this.selectedObject.current_type !== 'constant') {
        if (this.selectedObject.current_shape === 'pulse') {
          xlabel = 'LED drive current set voltage'
          set = '6'
        } else {
          xlabel = 'LED drive current'
          set = '6'
        }
      } else if (this.selectedObject.pulse_type !== 'constant') {
        xlabel = 'Pulses per ' + this.data.pulse_frequency + ' seconds'
        set = '7'
      } else if (this.selectedObject.duty_type !== 'constant') {
        xlabel = 'Duty cycle [%]'
        set = '7'
      }

      const coll1 = 'ltpda_reverse_ivp'
      const coll2 = 'ltpda_ideality_factor'

      this.writeRun = true
      this.matlabCode = 'clear all;\nclose all;\n\n' +
        '% Get Reverse VI data (from reverse IVP sweep)\n' +
        'db = {' + dbs.join(', ') + '};\n' +
        'uuids = {' + aos.join(', ') + '};\n' +
        'ipv = [];\n' +
        'for ii = 1:numel(uuids)\n' +
        'ipv = [ipv ao(plist(\'hostname\', \'localhost\', ...\n' +
        this.data.ltpda +
        '\'database\', db{ii}, ...\n' +
        '\'uuid\', uuids{ii}))];\n' +
        'end\n' +
        'ipv(' + set + ').setYaxisName(\'' + xlabel + '\');\n' +
        'ipv(' + set + ').setXaxisName(\'LED Voltage\');\n' +
        '\n' +
        '% iplot(abs(ipv(' + set + ')), plist(\'Markers\', repmat({\'o\'}, 1, numel(ipv(' + set + ')))))\n' +
        '% set(gca, \'YScale\', \'log\')\n' +
        '\n' +
        '% Get Forward VI data (from IVP sweep)\n' +
        'db = {' + rawdbs.join(', ') + '};\n' +
        'uuids = {' + rawaos.join(', ') + '};\n' +
        'aos = [];\n' +
        'for ii = 1:numel(uuids)\n' +
        'aos = [aos ao(plist(\'hostname\', \'localhost\', ...\n' +
        this.data.ltpda +
        '\'database\', db{ii}, ...\n' +
        '\'uuid\', uuids{ii}))];\n' +
        'end\n' +
        'aos(' + set + ').setYaxisName(\'' + xlabel + '\');\n' +
        'aos(' + set + ').setXaxisName(\'LED Voltage\');\n' +
        '\n' +
        '% iplot(abs(aos(' + set + ')), plist(\'Markers\', repmat({\'o\'}, 1, numel(aos(' + set + ')))))\n' +
        '% set(gca, \'YScale\', \'log\')\n' +
        '\n' +
        '% Stitch fwd and rev IV curves together\n' +
        'VI_j = join(ipv(' + set + '), aos(' + set + '));\n' +
        '\n' +
        '% Plot new, joined VI curve\n' +
        'VI_j.setYaxisName(\'' + xlabel + '\');\n' +
        'VI_j.setXaxisName(\'LED Voltage\');\n' +
        '\n' +
        'iplot(abs(VI_j), plist(\'Markers\', repmat({\'o\'}, 1, numel(VI_j))))\n' +
        'set(gca, \'YScale\', \'log\')\n' +
        '\n' +
        '[ISweepCurrents, joinedInd] = intersect(VI_j.x, aos(' + set + ').x, ...\n' +
        '    \'stable\');\n' +
        '\n' +
        'V_j = VI_j.x;\n' +
        'I_j = VI_j.y;\n' +
        '% %% Get leakage current (reverse current at max reverse Voltage\n' +
        'leakageCurrent = I_j(V_j == min(V_j));\n' +
        '% %% Determine ideality factor using fit method (before and after turn on)\n' +
        '\n' +
        '% Get indices of IV curve where the voltage is actually being\n' +
        '% increased (no current limit was hit)\n' +
        'noIlimitInds = find(abs(V_j(2:end)-V_j(1:end-1)) > .01) + 1;\n' +
        '\n' +
        'uniqueV = V_j(find(abs(V_j(2:end)-V_j(1:end-1)) > .01) + 1);\n' +
        'uniqueI = I_j(find(abs(V_j(2:end)-V_j(1:end-1)) > .01) + 1);\n' +
        '% For Voltages > ~200 mV, the -1 term in the general diode\n' +
        '% equation can be ignored\n' +
        'posV = uniqueV(uniqueV > 0.2);\n' +
        'posI = uniqueI(uniqueV > 0.2);\n' +
        '% take natural log of the diode current\n' +
        'lnI = log(posI);\n' +
        '\n' +
        '% Identify the 2 regions where ln(I) vs V should be linear\n' +
        '% 1 region before turn on, 1 region after turn on\n' +
        '\n' +
        '% diode turn on should start at first current step from the\n' +
        '% forward IVP, but subtracting 4 to ensure we capture the entire\n' +
        '% linear area\n' +
        'onInd1 = find(posV == V_j(joinedInd(1)))-1;\n' +
        '\n' +
        '% off region goes from lowest voltage (index 1) to ~start of on\n' +
        '% region, but adding 6 so we capture entire linear area\n' +
        'offInd2 = onInd1+3;\n' +
        '% offInd2 = 12;\n' +
        '\n' +
        '% second derivative in the region before turn on\n' +
        'd2_off = (lnI(3:offInd2)-2.*lnI(2:offInd2-1)+lnI(1:offInd2-2))./ ...\n' +
        '    ((posV(2:offInd2-1)-posV(1:offInd2-2)).*(posV(3:offInd2)-posV(2:offInd2-1)));\n' +
        '\n' +
        '% identify indices on the V-ln(I) curve where the curve is linear\n' +
        'offInds = 1:offInd2;\n' +
        '% Enforce that to identify a proper linear region, there must be\n' +
        '% \'minLength\' linear points in a row\n' +
        'minLength = 5;\n' +
        'offLinInds = offInds(boolean(consecutive(abs(d2_off)<0.25, minLength)));\n' +
        '% If there isn\'t \'minLength\' linear points in a row, relax the\n' +
        '% requirement until we can get at least 2 points in a row in this\n' +
        '% region\n' +
        'if isempty(offLinInds)\n' +
        '    while minLength > 2\n' +
        '        minLength = minLength-1;\n' +
        '        fprintf(\'Now trying to find %d consecutive linear points\\n\', ...\n' +
        '            minLength)\n' +
        '        offLinInds = offInds(boolean(consecutive(abs(d2_off)<0.25, minLength)));\n' +
        '        if ~isempty(offLinInds)\n' +
        '            break\n' +
        '        end\n' +
        '    end\n' +
        '    if isempty(offLinInds)\n' +
        '        warning(\'### Cannot identify linear region before turn on for %s\', ...\n' +
        '            VI_j.name(1:4))\n' +
        '        fprintf(\'Please select linear region before turn on\\n\')\n' +
        '        figure(99)\n' +
        '        plot(posV, lnI, \'bo-\')\n' +
        '        grid minor\n' +
        '        xlabel(\'Voltage [V]\')\n' +
        '        ylabel(\'ln(Current)\')\n' +
        '        title(\'Please select linear region before turn on\')\n' +
        '        [xClicks, ~] = ginput(2);\n' +
        '        lowBoundV = xClicks(1);\n' +
        '        upperBoundV = xClicks(2);\n' +
        '        offLinInds = find(posV > lowBoundV & posV < upperBoundV)\';\n' +
        '        close(99)\n' +
        '    end\n' +
        'end\n' +
        '\n' +
        '% Define how many points we look at for the second derivative of\n' +
        '% the linear area after turn on\n' +
        'MPlus   = 12;\n' +
        'M       = MPlus-1;\n' +
        'MMinus  = M-1;\n' +
        '\n' +
        '% second derivative in the region after turn on\n' +
        'd2_on = ((lnI(onInd1+1:onInd1+MPlus)-2.*lnI(onInd1:onInd1+M)+lnI(onInd1-1:onInd1+MMinus)))./...\n' +
        '    ((posV(onInd1:onInd1+M)-posV(onInd1-1:onInd1+MMinus)).*(posV(onInd1+1:onInd1+MPlus)-posV(onInd1:onInd1+M)));\n' +
        '\n' +
        'onInds = onInd1:onInd1+M;\n' +
        'minLength = 5;\n' +
        'onLinInds = onInds(boolean(consecutive(abs(d2_on)<0.25, minLength)));\n' +
        'if isempty(onLinInds)\n' +
        '    while minLength > 2\n' +
        '        minLength = minLength-1;\n' +
        '        fprintf(\'Now trying to find %d consecutive linear points\\n\', ...\n' +
        '            minLength)\n' +
        '        onLinInds = onInds(boolean(consecutive(abs(d2_on)<0.25, minLength)));\n' +
        '        if ~isempty(onLinInds)\n' +
        '            break\n' +
        '        end\n' +
        '    end\n' +
        '    if isempty(onLinInds)\n' +
        '        warning(\'### Cannot identify linear region after turn on for %s\', ...\n' +
        '            VI_j.name(1:4))\n' +
        '        fprintf(\'Please select linear region after turn on\\n\')\n' +
        '        figure(99)\n' +
        '        plot(posV, lnI, \'bo-\')\n' +
        '        grid minor\n' +
        '        xlabel(\'Voltage [V]\')\n' +
        '        ylabel(\'ln(Current)\')\n' +
        '        title(\'Please select linear region after turn on\')\n' +
        '        [xClicks, ~] = ginput(2);\n' +
        '        lowBoundV = xClicks(1);\n' +
        '        upperBoundV = xClicks(2);\n' +
        '        onLinInds = find(posV > lowBoundV & posV < upperBoundV)\';\n' +
        '        close(99)\n' +
        '    end\n' +
        'end\n' +
        '\n' +
        '% Define constants needed for General Diode Equation\n' +
        '% Elemantary Charge\n' +
        'q = 1.60217662e-19; % [C]\n' +
        '% Boltzman\'s Constant\n' +
        'k = 1.38064852e-23; % [J/K]\n' +
        '% Experiment Temperature\n' +
        'nameSpl = strsplit(VI_j.name, \'-\');\n' +
        'expTemp = str2double(nameSpl{4}(1:2));\n' +
        'T = 273.15+expTemp; % [K]\n' +
        '\n' +
        '% General Diode Equation\n' +
        '% I = Io[exp((qV)/(nkT)) - 1]\n' +
        '% because voltages are limited to values > 200 mV, general diode\n' +
        '% equation can be simplified to \n' +
        '% I = Io[exp((qV)/(nkT))]\n' +
        '% Linearizing this equation gives\n' +
        '% ln(I) = ln(Io) + (q/nkT)*V\n' +
        '% where ln(Io) is the y intercept, and q/nkT is the slope of a\n' +
        '% linear fit to the data\n' +
        '\n' +
        '% Fit to linear portion before turn on\n' +
        '[offFit, offGOF] = fit(posV(offLinInds), lnI(offLinInds), \'poly1\');\n' +
        '% Fit to linear portion after turn on\n' +
        '[onFit, onGOF] = fit(posV(onLinInds), lnI(onLinInds), \'poly1\');\n' +
        '\n' +
        '\n' +
        'fig = figure;\n' +
        'hold on\n' +
        'ivFig = plot(posV, lnI, \'bo-\');\n' +
        'hold on\n' +
        'offFitFig = plot(linspace(1, 4, numel(posV(offLinInds))), ...\n' +
        '    feval(offFit, linspace(1, 4, numel(posV(offLinInds)))), \'k--\');\n' +
        'offIndFig = plot(posV(offLinInds), lnI(offLinInds), \'go\');\n' +
        'onFitFig = plot(linspace(4.5, 5.5, numel(posV(onLinInds))), ...\n' +
        '    feval(onFit, linspace(4.5, 5.5, numel(posV(onLinInds)))), \'k--\');\n' +
        'onIndFig = plot(posV(onLinInds), lnI(onLinInds), \'go\');\n' +
        '\n' +
        'legend([ivFig, offIndFig, offFitFig], {VI_j.name, \'Linear Region\', ...\n' +
        '    \'Fit of linear Region\'}, \'Location\', \'northwest\')\n' +
        'grid on\n' +
        'xlabel(\'Voltage [V]\')\n' +
        'ylabel(\'ln(Current)\')\n' +
        'nOFF = q/(offFit.p1*k*T);\n' +
        'nON = q/(onFit.p1*k*T);\n' +
        '\n' +
        'offStr = sprintf(\'Ideality Factor (Off Region): %2.3f\\nR^2: %.4f\', ...\n' +
        '    nOFF, offGOF.rsquare);\n' +
        'onStr = sprintf(\'Ideality Factor (On Region): %2.3f\\nR^2: %.4f\', ...\n' +
        '    nON, onGOF.rsquare);\n' +
        '\n' +
        'text(1, -9, offStr)\n' +
        'text(3.5, -4, onStr)\n' +
        '\n' +
        '\n' +
        '% create data file\n' +
        'fileID = fopen(\'xy.txt\', \'w\');\n' +
        '\n' +
        '%write key labels\n' +
        '    fprintf(fileID, \'"%s" \', \'' + 'LED Voltage [V]' + '\');\n' +
        '    fprintf(fileID, \'"%s" \', \'' + this.led.internal_serialnumber + ' (' + this.data.temperature_min + ' C)\');\n' +
        '    fprintf(fileID, \'"%s" \', \'' + 'Ideality Factor Fit' + '\');\n' +
        '    fprintf(fileID, \'\\n\');\n' +
        '   \n' +
        'for i = 1:numel(VI_j.data.x)\n' +
        '    fprintf(fileID, \'%e \', VI_j.data.x(i));\n' +
        '    fprintf(fileID, \'%e \', VI_j.data.y(i));\n' +
        '    fprintf(fileID, \'\\n\');\n' +
        'end\n' +
        '\n' +
        'for i = 1:numel(offLinInds)\n' +
        '    fprintf(fileID, \'%e 0 \', posV(offLinInds(i)));\n' +
        '    fprintf(fileID, \'%e \', exp(feval(offFit, posV(offLinInds(i)))));\n' +
        '    fprintf(fileID, \'\\n\');\n' +
        'end\n' +
        '\n' +
        'for i = 1:numel(onLinInds)\n' +
        '    fprintf(fileID, \'%e 0 \', posV(onLinInds(i)));\n' +
        '    fprintf(fileID, \'%e \', exp(feval(onFit, posV(onLinInds(i)))));\n' +
        '    fprintf(fileID, \'\\n\');\n' +
        'end' +
        '\n' +
        'fclose(fileID);\n' +
        '\n' +
        '% read file content and submit to CMSdb\n' +
        'filetext = fileread(\'xy.txt\');\n' +
        '\n' +
        '% upload stitched VI AO to CMSdb\n' +
        'ltpda_reverse_ivp = submit([VI_j], ...\n' +
        'plist(\'hostname\', \'localhost\', ...\n' +
        '\'database\', \'' + coll1 + '\', ...\n' +
        '\'no dialog\', 1, ...\n' +
        this.data.ltpda +
        '\'experiment title\', \'' + this.led.internal_serialnumber + ': ' + 'Stitched VI Curve' + ' (Collection UUIDs:' + this.data.files[0].colluuid + ' and ' + this.data.files[0].rawselection + ')\', ...\n' +
        '\'experiment description\', \'' + this.data.title + ': ' + this.data.description + '\', ...\n' +
        '\'analysis description\', \'Combined data from Reverse IVP and IVP measurements\', ...\n' +
        '\'additional comments\', \'auto generated by CMSdb\') ...\n' +
        ');\n' +
        'submit_aolist(ltpda_reverse_ivp, \'' + this.data.files[0].name + '\', [VI_j], \'A1\', \'' + this.data.files[0].fileid + '\', \'' + this.userData.api_key + '\')\n' +
        'submit_gnuplot(\'' + fileid + '\', \'' + collid + '\', \'1\', \'' + this.selectedObject.rawivp + '\', filetext, \'' + this.userData.api_key + '\')\n' +
        '\n' +
        '\n' +
        '% %% Determine ideality factor as a function of Voltage (derivative method)\n' +
        '\n' +
        'n = zeros(1,numel(lnI)-1);\n' +
        '% Find dV/d(ln(I)) using fwd difference to find ideality factor\n' +
        '% at each voltage step\n' +
        'for ii = 1:numel(lnI)-1\n' +
        '    dVdlnI = (posV(ii+1)-posV(ii))/(lnI(ii+1)-lnI(ii));\n' +
        '    n(ii) = (q/(k*T))*dVdlnI;\n' +
        'end\n' +
        '\n' +
        '\n' +
        'nV = ao(plist(\'xvals\', posV(1:end-1), \'yvals\', n, ...\n' +
        '    \'xunits\', \'V\', \'name\', sprintf(\'%s-IdealityFactor-%s\', nameSpl{1}, \'' + this.data.temperature_min + 'C\')));\n' +
        'nV.setXaxisName(\'Voltage\');\n' +
        'nV.setYaxisName(\'Ideality Factor\');\n' +
        '\n' +
        'nV.setDescription(sprintf([\'IF_Off-Region %2.4f m_Off %2.4e \' ...\n' +
        '    \'b_Off %2.4e R2_Off %2.4f IF_On-Region %2.4f m_On %2.4e \' ...\n' +
        '    \'b_On %2.4e R2_On %2.4f Leakage_Current %2.4e A\'], nOFF, offFit.p1, offFit.p2, ...\n' +
        '    offGOF.rsquare, nON, onFit.p1, onFit.p2, onGOF.rsquare, leakageCurrent));\n' +
        '\n' +
        'iplot(nV, plist(\'Markers\', \'o\', \'LineColor\', \'b\'))\n' +
        '\n' +
        'figure(fig.Number)\n' +
        '\n' +
        '% create data file\n' +
        'fileID = fopen(\'xy2.txt\', \'w\');\n' +
        '\n' +
        '%write key labels\n' +
        '    fprintf(fileID, \'"%s" \', \'LED Voltage [V]\');\n' +
        '    fprintf(fileID, \'"%s" \', \'' + this.led.internal_serialnumber + ' (' + this.data.temperature_min + ' C)\');\n' +
        '    fprintf(fileID, [\'"Ideality Factor (off-region): %2.4f" \'], nOFF);\n' +
        '    fprintf(fileID, [\'"Ideality Factor (on-region): %2.4f" \'], nON);\n' +
        '    fprintf(fileID, [\'"Leakage current: %2.4e A"\'], leakageCurrent);\n' +
        '    fprintf(fileID, \'\\n\');\n' +
        '   \n' +
        'for i = 1:numel(nV.data.x)\n' +
        '    fprintf(fileID, \'%e \', nV.data.x(i));\n' +
        '    fprintf(fileID, \'%e \', nV.data.y(i));\n' +
        '    fprintf(fileID, \'%e \', -1);\n' +
        '    fprintf(fileID, \'%e \', -1);\n' +
        '    fprintf(fileID, \'%e \', -1);\n' +
        '    fprintf(fileID, \'\\n\');\n' +
        'end\n' +
        'fclose(fileID);\n' +
        '\n' +
        '% read file content and submit to CMSdb\n' +
        'filetext = fileread(\'xy2.txt\');\n' +
        '\n' +
        'ltpda_ideality_factor = submit([nV], ...\n' +
        'plist(\'hostname\', \'localhost\', ...\n' +
        '\'database\', \'' + coll2 + '\', ...\n' +
        '\'no dialog\', 1, ...\n' +
        this.data.ltpda +
        '\'experiment title\', \'' + this.led.internal_serialnumber + ': ' + 'Ideality factor as a function of Voltage (derivative method)\', ...\n' +
        '\'experiment description\', \'' + this.data.title + ': ' + this.data.description + '\', ...\n' +
        '\'analysis description\', nV.description, ...\n' +
        '\'additional comments\', \'auto generated by CMSdb\') ...\n' +
        ');\n' +
        'submit_aolist(ltpda_ideality_factor, \'' + this.data.files[0].name + '\', [nV], \'A2\', \'' + this.data.files[0].fileid + '\', \'' + this.userData.api_key + '\')\n' +
        'submit_gnuplot(\'' + fileid + '\', \'' + collid + '\', \'2\', \'' + this.selectedObject.rawivp + '\', filetext, \'' + this.userData.api_key + '\')\n'
    }
  }
}
</script>
<style scoped>
</style>
